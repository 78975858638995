<template>
  <b-container class="container">
    <b-row>
      <b-col md="3">
        <template v-if="$options.components['profile-need-help']">
          <profile-need-help />
        </template>
      </b-col>

      <b-col md="9">
        <div class="d-flex flex-column justify-content-between mt-4">
          <div class="d-flex justify-content-between mb-2">
            <h4 class="mb-2">Support Ticket</h4>
            <b-button
              @click="$bvModal.show('modal-raise-service-request')"
              style="cursor: pointer"
              class="btn-outline-primary colour"
            >
              Raise a Service Request
            </b-button>
          </div>
          <div class>
            <template>
              <div>
                <b-modal
                  hide-footer
                  id="modal-raise-service-request"
                  size="lg"
                  title="Raise a Service Request"
                >
                  <v-form>
                    <v-container>
                      <v-row>
                        <v-col block cols="12" sm="12" md="12">
                          <p label="Order #">Order # :{{ orderNumber }}</p>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" data-app>
                          <label for="serviceRequestIssueType"
                            >Select Issue Type</label
                          >
                          <b-form-select
                            v-model="selected"
                            :options="serviceRequestCategoryListItems"
                            text-field="category"
                            value-field="id"
                            size="sm"
                          ></b-form-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <label for="serviceRequestSubject">Issue</label>
                          <b-form-input
                            class="custom-select-sm"
                            label="Issue"
                            placeholder="Enter Issue Related to"
                            v-model="subject"
                            >{{ subject }}</b-form-input
                          >
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <label for="serviceRequestDescription"
                            >Description</label
                          >
                          <b-form-textarea
                            id="serviceRequestDescription"
                            rows="3"
                            no-resize
                            v-model="description"
                          >
                            {{ description }}
                          </b-form-textarea>
                        </v-col>

                        <b-col class="text-center">
                          <button
                            type="button"
                            class="btn btn-primary btn-lg mr-3"
                            @click="
                              $bvModal.hide('modal-raise-service-request')
                            "
                          >
                            <span class="button-text"></span> Cancel
                          </button>
                          <button
                            @click="
                              createTicket() &&
                                $bvModal.hide('modal-raise-service-request')
                            "
                            type="button"
                            class="btn btn-primary btn-lg"
                          >
                            <span class="button-text"></span> Submit Ticket
                          </button>
                        </b-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </b-modal>
              </div>
            </template>
          </div>
        </div>
        <span v-if="supportTicketListItems.length === 0">
          <div class="text-center mt-2 p-3 service-request">
            <b-img class="mb-3" src="img/empty_request.png"></b-img>
            <h3>No Service Request Available</h3>
            <button
              @click="$bvModal.show('modal-raise-service-request')"
              style="cursor: pointer"
              class="btn btn-outline-primary mt-3 mb-3"
            >
              Raise a Service Request
            </button>
          </div>
        </span>
        <span v-else>
          <b-table
            responsive
            head-variant="light"
            outlined
            :fields="fields"
            :items="supportTicketListItems"
          >
            <template #cell(ticketId)="data">
              <a @click="viewTicketDetail(data.item)" class="ticketId">
                {{ data.value }}
              </a>
            </template>
          </b-table>
        </span>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "support-ticket",
  data() {
    return {
      orderNumber: "",
      subject: "",
      description: "",
      selected: "",
      fields: [
        {
          key: "ticketId",
          formatter: "ticketDetail",
          tdClass: () => {
            return "onHover";
          },
        },
        {
          key: "date",
          formatter: "dateFormat",
        },
        {
          key: "description",
        },
        {
          key: "status",
          formatter: "ticketStatus",
          tdClass: (value) => {
            if (value === "OPEN") {
              return "text-green";
            } else {
              return "text-red";
            }
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      supportTicketData: (state) => state.profile.supportTicketData,
      serviceRequestCategoryList: (state) =>
        state.profile.serviceRequestCategoryList,
      userInfo: (state) => state.auth.userInfo,
      isLoading: (state) => state.isLoading,
      orderList: (state) => state.profile.orderList,
      orderDetailsData: (state) => state.profile.orderDetailsData,

      supportTicketListItems(state) {
        let supportTicketList = [];
        if (
          Array.isArray(state.profile.supportTicketData) &&
          state.profile.supportTicketData.length > 0
        ) {
          state.profile.supportTicketData.filter((obj) => {
            obj.reportedBy.id === this.userInfo._id;
            {
              let arrayObj = {
                orderId: obj?.order?.orderId || "",
                ticketId: obj?.ticketNo || "",
                date: obj?.createdAt || "",
                description: obj?.description || "",
                status: obj?.status || "",
                worknoteHistory: obj?.worknoteHistory || "",
                ticket_id: obj?._id || "",
                tenantId: obj?.tenant || "",
              };
              supportTicketList.push(arrayObj);
            }
          });
          let filteredSupportTicketList = supportTicketList.filter((obj) => {
            return this.orderNumber === obj.orderId;
          });
          return filteredSupportTicketList;
        } else {
          this.isloading = false;
          return [];
        }
      },

      serviceRequestCategoryListItems(state) {
        let srCategory = [];
        if (
          Array.isArray(state.profile.serviceRequestCategoryList) &&
          state.profile.serviceRequestCategoryList.length > 0
        ) {
          state.profile.serviceRequestCategoryList.map((obj) => {
            let arrObj = {
              category: obj.name,
              id: obj._id,
            };
            srCategory.push(arrObj);
          });
          return srCategory;
        } else {
          this.isloading = false;
          return [];
        }
      },

      sellerDetails(state) {
        let sellerData = [];
        if (
          Array.isArray(state.profile.orderList) &&
          state.profile.orderList.length > 0
        ) {
          state.profile.orderList.filter((obj) => {
            obj.orderId === this.orderNumber;
            {
              let arrObj = {
                id: obj.items[0]?.delivery?.deliveryOwner?._id || "",
                name: obj.items[0]?.delivery?.deliveryOwner?.name || "",
                email: obj.items[0]?.delivery?.deliveryOwner?.email || "",
                category: obj.items[0]?.delivery?.deliveryOwner?.category || "",
                phone: obj.items[0]?.delivery?.deliveryOwner?.phone || "",
              };
              sellerData.push(arrObj);
            }
          });
          return sellerData;
        } else {
          this.isloading = false;
          return [];
        }
      },
    }),
  },
  methods: {
    ticketDetail(value) {
      return value;
    },
    dateFormat() {
      return moment(this.supportTicketListItems.createdAt).format("DD/MM/YYYY");
    },
    ticketStatus(value) {
      const capitalized =
        value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      return capitalized;
    },
    viewTicketDetail(data) {
      this.$router.push({
        name: "support-ticket-detail",
        params: { id: data.ticket_id },
      });
    },

    async createTicket() {
      let payload = {
        category: this.selected,
        description: this.description,
        subject: this.subject,
        assignTo: {
          id: this.sellerDetails[0].id,
          name: this.sellerDetails[0].name,
          email: this.sellerDetails[0].email,
          userType: this.sellerDetails[0].category,
          phone: this.sellerDetails[0].phone,
        },
        reportedTo: {
          id: this.sellerDetails[0].id,
          name: this.sellerDetails[0].name,
          email: this.sellerDetails[0].email,
          userType: this.sellerDetails[0].category,
          phone: this.sellerDetails[0].phone,
        },
        order: {
          orderId: this.orderNumber,
        },
        reportedBy: {
          name: `${this.userInfo.name.first} ${this.userInfo.name.last}`,
          email: this.userInfo.authentication.email,
          userType: this.userInfo.category.toUpperCase(),
          phone: this.userInfo.authentication.phone,
        },
      };
      await this.$store.dispatch("profile/createServiceRequestTicket", payload);
      this.$swal({
        text: "Success",
        timer: 1100,
      });
      await this.$store.dispatch("profile/fetchSupportTicketData");
    },
  },

  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("profile/fetchOrderList");
    await this.$store.dispatch("profile/fetchSupportTicketData");
    await this.$store.dispatch("profile/fetchServiceRequestCategoryList");
    this.supportTicketListItems;
    this.orderNumber = this.$route.params.orderId;
    this.isLoading = false;
  },
};
</script>

<style scope>
.text-red {
  color: red;
}

.text-green {
  color: green;
}

.onHover {
  color: blue;
  cursor: pointer;
}
.service-request {
  border: 1px solid #efefef;
  border-radius: 10px;
}
.colour {
  background-color: #fe7b08 !important;
}
.ticketId {
  font-weight: 500;
}

.container {
  min-height: 403px;
}
</style>
